$black: #000;
$white: #fff;

$corn: rgb(255, 197, 80);
$corn-light: lighten($corn, 20%);

$primary: rgb(82, 99, 243);
$primary-light: rgb(116, 130, 245);

$warning-main: #f3cb64;

$soft-grey: #eaebf0;

// TODO: Figure out a mixin for this shading
$grey: #9e9e9e;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: $grey;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$text-primary: rgba($black, 0.87);
$text-secondary: rgba($black, 0.6);
$text-disabled: rgba($black, 0.38);

// For JS Imports
$exports: (
  black: $black,
  white: $white,
  corn: $corn,
  cornLight: $corn-light,
  primary: $primary,
  primaryLight: $primary-light,
  warningMain: $warning-main,
  softGrey: $soft-grey,
  textPrimary: $text-primary,
  textSecondary: $text-secondary,
  textDisabled: $text-disabled,
  grey: $grey,
  grey50: $grey-50,
  grey100: $grey-100,
  grey200: $grey-200,
  grey300: $grey-300,
  grey400: $grey-400,
  grey500: $grey-500,
  grey600: $grey-600,
  grey700: $grey-700,
  grey800: $grey-800,
  grey900: $grey-900,
);
